.map-modal
    .ant-modal-header
        min-height: 55px

    .ant-modal-body
        .ymap
            position: relative
            padding: 20px 0
            padding-bottom: 40px
            height: calc(100vh - 100px - 55px - 100px)
            min-height: 300px

            &.map-preview
                padding: 0

        .map-footer
            position: absolute
            bottom: 0
            line-height: 32px
            width: 100%

            .ant-btn
                float: right
                margin-left: 15px
            div
                float: left
                white-space: nowrap
                text-overflow: ellipsis
                width: calc(100% - 220px)
                overflow: hidden
