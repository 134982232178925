.attachment-audio-button
    position: absolute
    top: 0
    right: 74px

.attachment-audio-button-disabled
    color: rgba(0, 0, 0, 0.25)
    background-color: #f5f5f5
    border-color: #d9d9d9
    text-shadow: none
    box-shadow: none

    &:hover
            color: rgba(0, 0, 0, 0.25)
            background-color: #f5f5f5
            border-color: #d9d9d9
            text-shadow: none
            box-shadow: none
            cursor: not-allowed

.audio-button-popover
    z-index: 1000

    .ant-popover-content
        .ant-popover-arrow
            bottom: 5.4px
            border-right: 4px solid rgba(0, 0, 0, 0.35)
            border-bottom: 4px solid rgba(0, 0, 0, 0.35)
            border-left: 4px solid transparent
            border-top: 4px solid transparent
            background: transparent
            transform: translateX(-50%) rotate(45deg)
            left: 50%

        .ant-popover-inner
            background: rgba(0, 0, 0, 0.35)

    .ant-popover-inner-content
        padding: 12px 15px

        .ant-upload
            button
                color: #fff
                background-color: #002140
                border-color: #002140
                margin-right: 5px

                &:hover
                    background-color: #0c304d
                    border-color: #0c304d

.audio-recorder
    text-align: center
    width: 176px

    .hide-audio
        display: none

    canvas
        display: none

    audio
        display: none

    .audio-recorder-stopwatch
        color: #fff
        margin-bottom: 5px

    .audio-start-button
        width: 102px
        color: #ffffff
        margin: 10px 0
        background-color: #002140
        border-color: #002140
        box-shadow: 0 0 10px #002140

        &:hover
            background-color: #0c304d
            border-color: #0c304d
            box-shadow: #0c304d

    .audio-recorder-control
        opacity: 1

        &.hidden
            opacity: 0

        .audio-button
            margin-right: 10px
            color: #fff
            background-color: #002140
            border-color: #002140
            margin-right: 5px

            &:hover
                background-color: #0c304d
                border-color: #0c304d

            &:last-child
                margin-right: 0

        .audio-button[disabled]
            background-color: #d9d9d9
            border-color: #d9d9d9
            color: #000000

        .play-button
            width: 69px
            transition: 0s

        .play-button.isPlaying
            width: 32px

        .audio-send-button
            width: 100%

    .audio-recorder-error
        color: #fff
