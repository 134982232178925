@import 'variables'
@import 'dialog'
@import 'chat'
@import 'nodes'
@import 'editor'
@import 'avatarModal'
@import 'applicant'
@import 'companies'
@import 'botPrintModal'
@import 'landing'
@import 'media'
@import 'form'
@import 'vacancy'
@import 'webchatModal'
@import 'audio'
@import 'bots'
@import 'steps'
@import 'responseLoader'
@import 'videoInput'
@import 'searchInput'
@import 'table'
@import 'metro'
@import 'ymap'
@import 'draggable'
@import 'translation'
@import 'classifiers'
@import 'domains'
@import 'companyDictionary'
@import 'modal'
@import 'files'
@import 'collapse'

html, body, #root, .ant-layout
    height: 100%

a:hover
    color: $primary-color-hover

.ant-layout
    min-width: 850px

.ant-layout-header
    padding-left: 24px

.ant-layout .ant-layout-sider .ant-menu-inline
    // padding-left: 8px

.pre-wrap
    white-space: pre-wrap

    *
        line-height: 1em

.user-content
    overflow: hidden
    margin: 0 30px
    min-height: 100%
    @media (max-width: 1400px)
        margin: 0 16px

    & > div
        background: #fff
        margin: 30px 0
        @media (max-width: 1400px)
            margin: 16px 0

    .page-content
        padding: 30px

.user-layout-content
    padding: 0
    overflow-x: hidden

.experium-layout
    background: #fff

    .ant-layout-content
        overflow: auto

.ant-menu-item
    margin-top: 0 !important
    margin-bottom: 0 !important
    transition: none

.ant-menu-item-group
    padding-left: 8px

    &-title .anticon
        margin-right: 10px

    .ant-menu-item-group-list .ant-menu-item
        margin-left: 20px

.ant-menu-root.ant-menu-dark.ant-menu
    .ant-menu-submenu
        &.ant-menu-submenu-open
            .ant-menu-submenu-title
                color: rgba(255, 255, 255, 0.65)
.header-buttons-group
    display: inline-block
    float: right

.profile-button, .lang-button
    margin-right: 15px
    color: rgba(255, 255, 255, 0.65)
    &:hover
        color: #fff
        cursor: pointer

.profile-button .anticon
    margin-right: 8px

.logout-button
    color: rgba(255, 255, 255, 0.65)
    cursor: pointer
    &:hover
        color: #fff

.logout-button .anticon
    margin-right: 8px

.auth-spin-wrapper
    background: #ececec
    width: 100%
    height: 100%
    position: relative

    .ant-spin-spinning
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.guest-layout
    width: 400px

.guest-language
    position: absolute
    right: 15px
    top: 15px

    .ant-dropdown-trigger
        cursor: pointer

.guest-block
    position: relative
    background-color: #fff
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1)
    padding: 35px 40px

.guest-layout, .guest-content:before
    vertical-align: middle
    display: inline-block

.guest-content
    display: block
    text-align: center
    background: #ececec url("../images/bg-guest.jpg") center no-repeat
    background-size: cover

    .ant-input
        text-align: left

    &:before
        content: ''
        height: 100vh
        width: 1px

.form-alert
    margin-top: 20px
    margin-bottom: 25px

.toolbar
    text-align: right
    border-bottom: 1px solid #e8e8e8
    padding: 15px
    min-height: 64px
    &-actions
        display: flex
        justify-content: flex-end
        button,
        .ant-btn-group
            margin-left: 15px
    h1
        display: inline-block
        float: left
        line-height: 32px
        padding-left: 15px
    h2
        float: left
        line-height: 32px
    h3
        float: left
        line-height: 32px
        margin: 0
    &-questions
        border-bottom: none
        .add-button
            margin-left: 10px
        .language-button
            font-size: 23px
            vertical-align: middle
            margin-right: 10px
            width: 23px
            &:hover
                color: #0c304d
            &.translated
                color: #52c41a
    .ant-form-item-control
        line-height: 32px
    &:after
        content: ""
        display: table
        clear: both

.language-button
    cursor: pointer
    font-size: 23px
    vertical-align: top
    margin-right: 10px
    width: 23px
    &:hover
        color: #0c304d
    &.translated
        color: #52c41a
    svg
        display: flex
        height: 32px

.vacancy-language-button
    margin-right: 0

.vacancy-add-toolbar,
.company-add-toolbar
    display: flex
    text-align: left
    position: relative
    h1
        margin-bottom: 0
    .toolbar-language-dropdown
        position: absolute
        top: 20px
        right: 20px

.short-text
    overflow: hidden
    max-width: 450px
    line-height: 20px

.modal-form-toolbar
    text-align: right
    padding-top: 10px
    button
        margin-left: 15px
    .invite-button
        float: left
        margin-left: 0

.vacancy-toolbar
    .ant-btn-circle
        float: left
        line-height: 31px

.vacancy-block,
.vacancy-add-block,
.company-add-block
    padding: 15px

.vacancy-add-block
    background-color: #fff

.profile-block
    padding: 15px

.profile-block-settings
    .ant-form-item
        flex-direction: row-reverse
        align-items: center
        justify-content: flex-end
        margin-bottom: 10px
        .ant-form-item-label
            padding: 0
        .ant-form-item-control
            display: inline-block
            flex-grow: initial
            margin-right: 15px

.vacancy-edit-block
    .ant-tabs-tabpane
        padding: 0 15px
        padding-bottom: 15px
    .modal-form-toolbar
        padding: 15px
    .user-tabs
        .ant-tabs-tabpane
            padding: 0

.vacancy-block
    .vacancy-block_bots-qr
        margin-right: 20px
    &_description-info
        margin-bottom: 15px
        & > div
            margin: 3px 0 10px

.vacancy-block
    &-title
        display: inline-block

.select-filter,
.input-filter
    width: 100%

.text-center
    text-align: center

.text-left
    text-align: left

.text-right
    text-align: right

.choose-filter
    height: 100px
    text-align: center
    padding-top: 40px

.select-icon
    width: 16px
    height: 16px
    display: inline-block
    position: relative
    margin-left: 11px
    top: -1px
    right: 5px

.error-message
    color: #f5222d
    font-size: 12px
    line-height: 1.4

.footer-error
    padding: 10px 15px
    padding-bottom: 0

.vertical-checkbox-group
    .ant-checkbox-group-item
        display: block

.date-select
    width: 280px

.date-select + .ant-checkbox-wrapper,
.ant-picker + .ant-checkbox-wrapper
    margin-left: 10px

.filter-form
    .ant-form-item
        margin-bottom: 5px
    .ant-picker-range
        width: 100%

.ant-input-number
    .ant-input-number-input, .ant-input-number-input-wrap
        width: 100%

.copy-block
    cursor: pointer
    background: #f8f8f8
    padding: 10px
    padding-right: 30px
    display: inline-block
    width: 100%
    position: relative
    border-radius: 4px
    .anticon-copy
        position: absolute
        top: 13px
        right: 9px
        font-size: 16px
        display: none
    &:hover .anticon-copy
        display: inline-block

.deep-link
    margin-right: 5px
    color: #fff

    &:hover, &focus
        color: inherit
        text-decoration: underline

    &:last-child
        margin-right: 0

.deep-link_icon
    height: 20px

.bot-select
    .deep-link:first-child
        margin-right: 5px

.toolbar-button
    margin-right: 10px

    &:last-child
        margin-right: 0

.logo
    background: url("../images/logo.svg") center no-repeat
    width: 20px
    height: 20px
    background-size: contain
    display: inline-block
    &-wrapper
        vertical-align: middle
        display: inline-block
        margin-right: 5px

.vacancy-title
    vertical-align: middle

.exported
    &-true
        color: #52c41a
    &-false
        color: #f5222d

.applicant-fields
    margin-top: 20px

.empty-bots
    text-align: center

.error-tab
    color: #f5222d

.wrap-img
    margin: 15px 0
    img
        max-width: 100%
.manual-modal
    .ant-modal-body
        padding-top: 0
    ol
        padding: 0 30px

.wrap-messenger
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    min-height: 100vh
    background: #ececec
    text-align: center
    vertical-align: middle
    overflow: hidden
    &-container
        margin: 0 16px
    h1
        font-size: 2.5em
        @media screen and (max-width: 576px)
            font-size: 2em
    h2
        margin-bottom: 0
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    & > div
        width: 100%

.box-messenger
    padding: 40px 5px
    margin-bottom: 16px
    text-align: center
    box-shadow: 0 1px 2px rgba(0,0,0,.075)
    transition: background 0.4s linear, filter .2s linear, box-shadow 0.1s linear
    cursor: pointer
    background: #f5f5f5
    filter: grayscale(50%)
    &:hover
        background: #fff
        filter: grayscale(0%)
    &:active
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
    @media screen and (max-width: 576px)
        padding: 15px

.icon
    display: inline-block
    background-size: 100px auto
    height: 100px
    width: 100px
    @media screen and (max-width: 576px)
        background-size: 50px auto
        height: 50px
        width: 50px

.telegram
    background-image: url("../images/telegram.svg")

.viber
    background-image: url("../images/viber.svg")

.facebook
    background-image: url("../images/facebook.svg")

.web
    background-image: url("../images/logo-bot.svg")

.ant-layout-sider-trigger
    position: absolute

.about-company-wrapper
    padding: 15px

@media (max-width: 1020px)
    .hide-lg
        display: none
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td
        padding: 8px

.central-spin
    display: flex
    justify-content: center
    padding-bottom: 60px
    padding-top: 60px

.date-time-string .time
    margin-left: 8px

.dictionaries .ant-tabs-bar
    margin-bottom: 5px

.logo-uploader
    &-image
        width: 100px
        height: 100px
        background-position: center
        background-size: cover
    & > .ant-upload
        width: 116px
        height: 116px
    & > .ant-upload-disabled
        border-style: solid
        &:hover
            border-color: #d9d9d9
            cursor: default
    &-hover
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        background: rgba(0, 0, 0, .5)
        opacity: 0
        transition: all .3s
        .delete-btn
            background: none
            border: none
            color: #fff
            opacity: .8
            cursor: pointer
            transition: all .3s
            font-size: 18px
            &:hover
                opacity: 1
            &:focus
                outline: none
        &:hover
            opacity: 1

.ant-upload + .ant-upload-list
    margin-top: 15px

.settings-tabs
    overflow: visible
    .ant-tabs-bar
        margin: 0

.error-component
    padding: 25px
    text-align: center

    .error-component-button
        margin-right: 20px

        &:last-child
            margin-right: 0

.avatar
    &:hover
        cursor: pointer

    &.with-image
        img
            max-height: 32px
            width: auto

.without-image
    &:hover
        cursor: default

.ant-dropdown-menu
    padding: 0
    overflow: hidden

.deeplink-menu
    padding: 0

    .ant-dropdown-menu-item
        padding: 0

        a
            margin: 0
        span
            padding: 5px 12px
            display: inline-block

.manual-tab-title
    .deep-link_icon
        display: inline

    .manual-tab-text
        display: inline
        margin-left: 5px

.dropdown-component
    white-space: nowrap
    &>:hover
        z-index: 1

    &>:focus
        z-index: 1

    .dropdown-qrcode-button
        border-radius: 4px 0 0 4px

    .dropdown-link-button
        border-radius: 0
        margin-left: -1px

    .dropdown-button
        border-radius: 0 4px 4px 0
        margin-left: -1px

.link-block
    margin-top: 10px
    a
        color: #002140
.logo-wrap
    display: flex
    justify-content: center
    img
        height: 35px
        margin-top: 8px
        margin-bottom: 3px
.logo-bot
    background: url("../images/logo-bot.svg") center no-repeat
    width: 50px
    height: 50px
    background-size: contain
    display: inline-block
.logo-text
    font-size: 32px
    color: #000
    line-height: 60px
    margin-left: 10px
.tagline
    color: #272727
    margin-bottom: 30px
    margin-top: 10px
    padding-bottom: 15px
    &:after
        content: ''
        left: 0
        right: 0
        position: absolute
        height: 1px
        background: #ccc
        display: block
        margin-top: 25px
.reg-info
    margin-top: 20px
    color: #fff
    a
        color: #fff
        font-weight: bold
    small
        margin-left: 5px

.ant-input:focus,.ant-select-open .ant-select-selection
    box-shadow: none

.ant-tabs-nav
    .ant-tabs-tab
        padding-left: 16px
        padding-right: 16px

.span-link
    cursor: pointer
    color: #002140

.catch-error
    text-align: center
    padding: 20px

.companyName
    display: inline-block
    color: #ffffff
    font-weight: bold
    font-size: 32px

@media print
    .print-qr
        .konvajs-content
            transform-origin: 0 0
            transform: scale(1.45)

.hide
    display: none

.row
    display: table-row

.ant-btn-group
    .ant-btn, .ant-btn-danger
        &[disabled]
            border-color: $primary-color
            &:hover, &:focus, &:active
                border-color: $primary-color

.ant-btn-icon-only
    vertical-align: 0
