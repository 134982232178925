.wysiwyg-wrapper
    padding: 0
    border: solid 1px rgba(0, 0, 0, 0.25)
    border-radius: 5px

    &.focused
        border-color: $primary-color

    .rdw-editor-main
        ul, ol
            margin: 0

    .rdw-editor-toolbar
        padding: 4px
        border: none
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.2)

        .rdw-inline-wrapper
            margin-bottom: 0

        .rdw-option-wrapper
            min-width: 30px
            height: 30px
            border: none

            &:hover
                background-color: rgba(0, 0, 0, 0.05)
                box-shadow: none

        .rdw-option-active
            background-color: rgba(0, 0, 0, 0.05)
            box-shadow: none

        .rdw-emoji-wrapper
            height: 30px
            margin-bottom: 0

        .rdw-emoji-wrapper-active
            background-color: rgba(0, 0, 0, 0.05)

    .DraftEditor-root
        min-height: 60px

.wysiwyg-editor
    padding: 4px 11px
    min-height: 60px
    overflow: auto

    &.rdw-editor-main
        .rdw-link-wrapper a, .rdw-link-decorator-wrapper a
            text-decoration: underline
            color: #2e91d1

.wysiwyg-disabled
    cursor: not-allowed

.public-DraftStyleDefault-block
    line-height: initial
    margin: 0
    margin-bottom: 3px
    font-size: 14px
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif

.public-DraftEditorPlaceholder-inner
    line-height: normal
    font-size: 14px

.dropdown-content
    box-sizing: content-box
    position: absolute
    z-index: 10
    top: 5px
    left: 50%
    float: left
    width: auto
    min-width: 100%
    margin-top: 9px
    background-color: #21242a
    border-radius: 3px
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2)
    cursor: default
    transform: translate(-50%, 20px)
    transition: .2s

    .dropdown-arrow
        position: absolute
        top: -10px
        left: 50%
        border: 5px solid transparent
        border-bottom-color: #21242a
        transform: translateX(-50%)
        transition: margin .2s

    .dropdown-content-inner
        overflow: auto
        max-height: 470px

        .braft-emojis-wrap
            position: relative
            width: 210px
            height: 220px
            overflow: hidden

            &:before
                position: absolute
                z-index: 1
                right: 0
                left: 0
                height: 30px
                border-radius: 3px
                content: ""
                pointer-events: none
                top: 0
                background-image: linear-gradient(0deg, rgba(33, 36, 42, 0), #21242a)

            &:after
                position: absolute
                z-index: 1
                right: 0
                left: 0
                height: 30px
                border-radius: 3px
                content: ""
                pointer-events: none
                bottom: 0
                background-image: linear-gradient(rgba(33, 36, 42, 0), #21242a)

            .braft-emojis
                box-sizing: content-box
                width: 200px
                height: 195px
                list-style: none
                margin: 0
                padding: 15px 15px 20px
                overflow: auto

                li
                    display: block
                    float: left
                    width: 30px
                    height: 30px
                    margin: 0
                    padding: 0
                    color: #fff
                    border-radius: 3px
                    font-size: 18px
                    line-height: 32px
                    text-align: center
                    cursor: pointer

                    &:hover
                        background-color: #3498db

.placeholder-url
    & > div
        text-align: center
        padding: 20px
    a
        display: inline-block
        border-radius: 4px
        color: #fff
        font-size: 16px
        background-color: #002140
        text-decoration: none !important
        padding: 16px 40px
        text-transform: uppercase
