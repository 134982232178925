.applicant-wrapper
    .avatar
        margin-left: 10px

    .applicant-toolbar
        position: relative
        border-bottom: 1px solid #e8e8e8
        padding: 0 15px
        height: 64px
        background: #fff
        display: flex
        align-items: center
        justify-content: space-between

        h1
            padding-left: 5px
            margin-bottom: 0

        .applicant-toolbar-group
            height: 64px
            display: flex
            align-items: center

            .ant-avatar
                img
                    height: auto

        .deep-link_icon + a
            margin-left: 15px

        .applicant-toolbar-actions
            position: absolute
            right: 18px
            top: 18px

    .pda-modal-button
        margin-left: 10px
