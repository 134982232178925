.web-chat-modal
    @media screen and (max-width: 576px)
        top: 0

    .ant-modal-body
        .web-chat-modal-buttons
            text-align: center

            .web-chat-modal-button
                height: initial
                padding: 10px
                margin-right: 10px

                &:last-child
                    margin-right: 0

                i
                    width: 100%
                    display: block
                    font-size: 36px
                    margin-bottom: 5px

    .webcam-container
        .hide-video
            display: none

        .media-wrapper
            height: 480px
            margin-bottom: 20px

            .media-preview
                width: 100%
                height: 480px

            .webcam-video
                @media screen and (max-width: 576px)
                    width: 100%
                    max-height: 220px

            .webcam-video.hidden
                display: none

        .video-recorder-stopwatch
            position: relative
            text-align: center

            @media screen and (min-width: 577px)
                position: absolute
                bottom: 24px
                left: 50%
                transform: translate(-50%)
                line-height: 32px

            &.hide
                display: none

        .webcam-footer
            display: flex
            justify-content: space-between

        .ant-spin.ant-spin-spinning
            max-height: 480px

.media-upload
    display: inline-block

.media-input
    display: none

@media screen and (max-width: 576px)
    .ant-modal.web-chat-modal
        margin: 0
        width: 100%

        .webcam-container
            .media-wrapper
                .media-preview
                    margin-bottom: 20px

            .webcam-footer
                margin: 0

                .media-upload
                    width: 100%

                .media-custom-button
                    width: 100%

                button
                    margin-right: 20px

                    &:last-child
                        margin: 0
