.bot-print-modal
    .ant-modal-body
        padding: 0

.bot-qr-block
    margin-bottom: 15px
    text-align: center
    padding: 64px 24px
    font-size: 1.4em

    p:last-child
        margin-bottom: 0

    .bot-qr-code
        margin-bottom: 10px

    .bot-qr-title
        margin-top: 10px
        &-item
            white-space: nowrap
        & > div
            display: inline-block
            margin-right: 15px
        &::last-child
            margin-right: 0
        .bot-qr-title-text
            font-weight: bold
    &:last-child
        margin-bottom: 0