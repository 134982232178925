.search-input
    line-height: 1.5

    &__input
        position: relative
        top: -2px

    .toolbar .ant-form-item-control &
        margin-top: 0

    .search-input__menu
        min-width: 100%
        z-index: 10
        overflow: hidden

    .search-input__value-container
        height: 32px
        .search-input__single-value
            margin-top: -2px

    .search-input__control
        min-height: 32px
        height: 32px

        &.search-input__control--is-focused
            border-color: #0c304d
            box-shadow: initial
        &:hover
            border-color: #0c304d
        &:focus
            border-color: #0c304d
            box-shadow: 0 0 0 1px red
        .search-input__placeholder
            margin-top: -2px
            color: #bfbfbf
            display: block
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden

    .search-input__option
        padding-bottom: 0
        padding-top: 5px
        text-align: left
        cursor: pointer
        pointer-events: auto
        &--is-selected
            background: #002140
        & > div,
        small
            display: block
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            cursor: pointer
            pointer-events: inherit

        &:not(.search-input__option--is-focused):not(.search-input__option--is-selected) small
            color: #aaa

    .search-input__clear-indicator
        padding: 0

    .search-input__dropdown-indicator
        padding: 0
        padding-right: 8px

    .ant-spin.ant-spin-sm.ant-spin-spinning
        line-height: 100%

    &.search-multi
        .search-input__value-container
            height: auto
            & > div:last-child
                margin: 0 2px
            .search-input__multi-value__label
                padding: 2px 3px
        .search-input__placeholder
            color: #bfbfbf
        .search-input__control
            height: auto

.no-options
    height: 30px
    text-align: center
    line-height: 30px

.ant-input-search-button
    border-color: #d9d9d9
