@import 'variables'

.dialog-toolbar
    border-bottom: 1px solid #e8e8e8
    padding: 0 15px
    height: 64px
    background: #fff
    display: flex
    align-items: center
    justify-content: space-between

    h1
        padding-left: 5px
        margin-bottom: 0

    .dialog-toolbar-group
        height: 64px
        display: flex
        align-items: center

        .ant-avatar
            img
                height: auto

.dialog-page
    background-color: #f0f2f5
    position: relative

    .avatar
        margin-left: 10px

.dialog-go-back
    position: absolute
    top: 0
    left: 0

.dialog-background
    background: url('../images/bg-bot.jpg') center no-repeat
    height: calc(100vh - 60px - 64px - 64px - 55px)
    display: flex
    flex-direction: column
    align-items: center
    &.admin-dialog
        height: calc(100vh - 60px - 64px - 64px - 100px)
    &.hide-operator
        height: calc(100vh - 60px - 64px - 64px)

.dialog-controls
    background: #fff
    padding: 15px
    form
        margin-top: 15px
        .ant-form-item
            padding-right: 42px

.dialog-wrapper
    height: 100px
    flex-grow: 1
    width: 100%

    .dialog-cards
        padding-right: 30px
        padding-top: 40px
        width: 600px
        margin: 0 auto
        position: relative
        @media screen and (max-width: 576px)
            width: 100%
            padding-right: 10px


    .prev-messages-row
        text-align: center
        margin-bottom: 10px
        height: 26px
        position: absolute
        top: 15px
        right: 0
        left: 0

    .date-row
        text-align: center
        padding: 5px 0
        font-weight: bold
        opacity: .8
        @media screen and (max-width: 576px)
            opacity: 1

.ant-row.toolbar
    .ant-calendar-picker-input.ant-input
        text-align: left
        padding: 4px 0px

.dialog-row-user
    text-align: left

.dialog-row-bot
    text-align: right

.dialog-message
    display: inline-block
    text-align: left
    animation: opacity 1s

    @keyframes opacity
        0%
            opacity: .4
        50%
            opacity: 1

    &-user
        padding-left: 50px

    &-bot
        padding-right: 50px

.dialog-card
    margin-bottom: 20px
    position: relative
    border-radius: 15px
    padding: 5px
    min-height: 40px

    &:before
        content: ''
        width: 12px
        height: 12px
        position: absolute
        border-top: 5px solid transparent
        border-bottom: 5px solid transparent

    &:after
        content: ''
        width: 10px
        height: 10px
        position: absolute
        border-top: 5px solid transparent
        border-left: 5px solid transparent
        border-bottom: 5px solid #fff
        border-right: 5px solid #fff

    .ant-card-body
        padding: 0px 10px 0 10px

    .avatar-logo-wrapper
        width: 100%
        height: 100%
        border-radius: 5px
        overflow: hidden
        background-position: center
        background-size: auto 80%
        background-color: white
        background-repeat: no-repeat
        position: relative

    .dialog-card-time
        display: inline-block
        width: 100%
        text-align: center
        color: #fff
        font-size: 12px
        @media screen and (max-width: 576px)
            color: #000

    &-user
        .user-avatar
            width: 40px
            height: 40px
            position: absolute
            bottom: 5px
            left: -50px

            img
                max-height: 40px
                width: auto
                background-color: #fff
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)

        &:before
            border-right: 6px solid #e8e8e8
            left: -13px
            bottom: 19px

        &:after
            border-right: 5px solid #fff
            left: -4px
            bottom: 20px
            transform: rotate(135deg)

    &-bot
        .bot-avatar
            width: 40px
            height: 40px
            position: absolute
            bottom: 5px
            right: -50px

            img
                height: 100%
                background-color: white

        &:before
            border-left: 5px solid #e8e8e8
            right: -11px
            bottom: 20px

        &:after
            right: -3px
            bottom: 20px
            transform: rotate(-45deg)

    &-header
        overflow: auto
        font-weight: bold

        .dialog-card-user
            float: left
            padding-right: 15px
            display: inline-block
            word-break: break-all
            vertical-align: top

    .dialog-item
        max-width: 330px

        &.menu
            max-width: 440px

    .dialog-items
        clear: both
        max-width: 440px

        .dialog-text
            margin-bottom: 10px
            white-space: pre-wrap

            &:last-child
                margin-bottom: 0

        .dialog-list
            display: flex
            flex-direction: column
            margin-bottom: 10px
            padding: 0
            align-items: flex-start

            &-item
                list-style-type: none
                background-color: $primary-color
                color: #ffffff
                padding: 10px
                border-radius: 10px
                margin-bottom: 10px
                width: 100%
                word-break: break-all

                &:hover
                    cursor: default

                &:first-child
                    margin-top: 0

        .dialog-menu-text
            margin-bottom: 10px

        .dialog-menu
            padding: 0
            margin: 0

            &-item
                display: inline-block
                width: 215px
                list-style-type: none
                background-color: $primary-color
                border-color: $primary-color
                color: #fff
                border: 1px solid transparent
                text-align: center
                line-height: 32px
                font-weight: 400
                height: 32px
                padding: 0 15px
                border-radius: 4px
                margin-right: 10px
                margin-bottom: 10px

                &:hover
                    cursor: default

                &:nth-child(even)
                    margin-right: 0

                &:nth-child(odd)
                    &:last-child
                        width: 100%
                        margin-right: 0

        .dialog-vacancy
            margin-bottom: 10px

            &-title
                padding-bottom: 10px
                font-weight: bold

            &-description
                padding-bottom: 10px

            &-button
                background-color: $primary-color
                color: #ffffff
                text-align: center
                padding: 10px
                border-radius: 10px
                margin-bottom: 10px

                &:hover
                    cursor: default

.applicant-title
    margin-top: -1px
    background-color: #f5f5f5
    color: #444c63
    font-size: 16px
    height: 54px
    line-height: 54px
    padding: 0 25px
    font-weight: 500
    border-top: 1px solid #e6e6e6
    border-bottom: 1px solid #e6e6e6

.empty-response
    text-align: center
    padding: 10px 15px

.form-item-group
    padding: 0 25px

.dialog-list-avatar
    .ant-avatar
        img
            width: auto
            max-height: 32px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)

.ant-table .last-message
    position: relative
    overflow: hidden
    width: 100%
    height: 21px

    span
        display: inline-block
        position: absolute
        left: 0
        top: 0
        width: 268px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

.dialog-preview
    margin-top: 20px
    background: url('../images/bg-bot.jpg') center no-repeat
    padding: 20px
