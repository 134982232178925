.avatar-modal
    max-height: 80vh

    .ant-modal-body
        height: calc(80vh - 24px - 24px - 55px)
        padding: 0
        position: relative

        img
            padding: 24px
            display: block
            height: 100%
            max-height: 640px
            max-width: 768px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
