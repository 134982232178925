.ant-modal
    .ant-modal-content
        border: 1px solid #ccc

.composite-question-preset
    .ant-modal-body
        .toolbar-questions
            padding: 0

#widget-preview
    padding-top: 50px
    padding-bottom: 30px

.modal-help
    background-color: #fff2f0
    border: 1px solid #ffccc7
    padding: 15px 15px 15px 60px
    border-radius: 2px
    margin-bottom: 20px
    position: relative

    .anticon
        position: absolute
        left: 15px
        top: 50%
        margin-top: -12px
        font-size: 24px
