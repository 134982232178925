.ant-table
    .main-column
        min-width: 150px
        max-width: 220px

    .table-item
        margin-bottom: 10px

    .ant-table-thead
        & > tr > th
            border-color: #e6e6e6

    .table-icon
        width: 20px
        height: 20px
        display: inline-block
        position: relative
    @media (max-width: 1400px)
        font-size: 13px


.ant-table-pagination
    padding: 0 16px

.ant-table-thead > tr > th, .ant-table-tbody > tr > td
    word-break: normal

.units-table
    .ant-table-expanded-row > td
        padding: 0
        padding-left: 50px
    .ant-spin-nested-loading
        .ant-spin-container
            .ant-table-placeholder
                .ant-table-expanded-row-fixed
                    background-color: #fafafa
                    &:hover
                        background-color: #fafafa
            .ant-table-fixed-column
                margin: 0
                .ant-table-content
                    overflow-x: auto!important
    .ant-table
        tbody > tr
            .ant-table-row:hover > td
                background-color: #e6f7ff
            .ant-table-row > td
                background-color: #fafafa

.ant-table-wrapper
    .ant-spin-nested-loading
        .ant-spin-container
            .ant-table
                .ant-table-container
                    .ant-table-content
                        overflow-x: auto!important

.published-date
    .ant-tag:last-child
            margin-right: 0

.published-date-popover
    .ant-popover-inner-content
        padding: 6px
        .ant-tag:last-child
            margin-right: 0

.table-toolbar-buttons
    padding-left: 8px
    .ant-btn
        width: 100%
        margin-bottom: 20px

.table-error
    .table-error-message
        color: #f5222d
        padding-right: 5px

.section-buttons
    .ant-btn
        margin-right: 5px
    .ant-btn:last-child
        margin-right: 0

.add-section-column
    .ant-table-row-expand-icon
        display: none

.table-confirm-title
    max-width: 520px

.ant-table-cell
    .pda-result
        white-space: nowrapp
        button
            margin-left: 5px
