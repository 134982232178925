.bot-title
    white-space: nowrap
    display: inline-block
    margin-right: 15px
    line-height: 30px

    .deep-link img
        margin-top: -5px

    .bot-title-text
        font-weight: bold

.social
    text-align: center

.vacancy-questions
    &-preset
        display: flex
        width: 100%
        &-col-block
            width: 65%
        &-col-reserved
            width: 35%
            position: relative
        &-col
            width: 50%
            &:nth-child(1)
                padding-right: 15px
                border-right: 1px solid #e8e8e8
            &:nth-child(2)
                padding: 0 15px
            &:nth-child(3)
                padding-left: 15px
                border-left: 1px solid #e8e8e8
        &-title
            color: rgba(0,0,0,.45)
            margin-bottom: 10px
        &-help
            font-size: 11px
    &-item
        display: flex
        margin: 8px 0
        .ant-checkbox-wrapper
            margin-right: 10px
    &-separator
        width: 100%
        height: 1px
        background: #ccc
        margin: 25px 0 15px
    &-collection
        display: flex
        align-items: top
        padding-bottom: 5px
        .ant-form-item
            margin-bottom: 0
            margin-right: 10px
            width: 100%

.vacancy-edit-tabs
    overflow: visible

.vacancy-toolbar
    .ant-btn
        .anticon
            vertical-align: 0
    .vacancy-block-title
        margin-bottom: 0
        display: flex
        align-items: center
        svg
            margin-right: 7px

.block-questions
    padding-left: 16px

.vacancy-domains-wrapper
    display: flex
    max-width: 100%
    .vacancy-domains
        max-width: calc(100% - 60px)
        padding-left: 3px
        display: flex
        flex-direction: column
        .vacancy-domain-link
            width: 100%
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

.terminat-title
    display: block
    margin-bottom: 15px

.tree-node-remove
    color: #cccccc
    transition: all .3s
    padding-left: 5px

    &:hover
        color: black

.classifier-edit
    text-decoration: underline

.ant-btn .ant-badge.primary
    margin-left: 5px

.ant-badge.primary .ant-badge-count
    background-color: #002140
