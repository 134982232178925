.landing-modal.ant-modal
    top: 0
    bottom: 0
    padding: 0
    margin: 0
    border-radius: 0
    overflow: hidden
    max-width: 100vw

    .ant-modal-body
        padding: 0

    .ant-modal-content
        border-radius: 0
