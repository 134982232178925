.file-types-wrapper
    margin-bottom: 10px
    .ant-divider
        margin: 0
    .default-types
        margin-bottom: 8px
    .additional-types
        display: flex
        .additional-type
            width: 100px
            display: flex
            justify-content: space-between
            align-items: center
            margin-right: 8px
        .ant-checkbox-wrapper
            display: flex
            flex-wrap: nowrap
            align-items: center
        .additional-type-text
            width: 44px
            overflow: hidden
            text-overflow: ellipsis

    .additional-type-input-buttons
        padding-top: 8px
        .ant-btn:first-child
            margin-right: 10px
    .ant-checkbox-group
        width: 100%
        .ant-checkbox-group-item
            width: 100px

.additional-type-input
    .additional-type-input-divider
        margin-top: 8px
        margin-bottom: 8px
    .ant-row.ant-form-item
        flex-grow: 1
        width: 50%
        margin: 0

        &:first-child
            padding-right: 16px
    .additional-type-input-buttons
        width: 100%
