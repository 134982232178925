.bot-modal-selector
    text-align: center
    h3
        margin-bottom: 20px

.facebook-create-bot
    .ant-steps-item-icon
        margin-left: 20px
    .ant-steps-item-tail
        margin-left: 22px
    .ant-steps-item-content
        width: 50px
