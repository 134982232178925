@import 'variables'

.web-chat
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    width: 100%
    height: 100vh
    box-sizing: border-box
    position: relative
    background: url("../images/bg-bot.jpg") center no-repeat
    background-size: cover

    @media screen and (max-width: 576px)
        background: url("../images/bg-bot-mobile.png") center repeat
        background-size: 70%

    .chat-reset-button
        position: absolute
        z-index: 10
        right: 20px
        top: 20px

        @media screen and (max-width: 576px)
            right: 5px
            top: 10px
            height: 20px
            width: 20px
            font-size: 13px

    .dialog-wrapper
        flex-grow: 1
        height: 100px

        .prev-messages-row
            text-align: center
            margin-bottom: 10px
            position: absolute
            top: 15px
            right: 0
            left: 0

        .download-message-eror
            margin-bottom: 10px
            max-width: 380px
            margin: auto

            .ant-alert-message
                display: none

            .ant-alert-close-icon
                color: #1890ff

        .dialog-cards
            position: relative
            padding-top: 40px
            padding-right: 30px
            padding-left: 5px

        .scroll-placeholder
            height: 500px

        @media screen and (max-width: 576px)
            width: 100%

    .chat-input-wrapper
        margin-bottom: 52px
        margin-top: 10px
        width: 600px
        border-radius: 5px
        padding: 10px
        background: rgba(0, 0, 0, 0.35)

        @media screen and (max-width: 576px)
            width: 100%
            margin: 0

    .chat-menu
        text-align: center
        border-radius: 0 0 5px 5px

        @media screen and (max-width: 576px)
            width: 100%
            bottom: 0
            border-radius: 0

        .chat-menu-buttons
            .chat-menu-button
                margin-top: 10px
                display: inline-block
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                margin-right: 10px
                transition: initial
                width: 285px

                @media screen and (max-width: 576px)
                    width: 100%
                    margin-top: 1px
                    border-radius: 0

                    &:first-child
                        margin-top: 8px
                        border-top-left-radius: 5px
                        border-top-right-radius: 5px

                    &:last-child
                        border-bottom-left-radius: 5px
                        border-bottom-right-radius: 5px

                &:nth-child(even)
                    margin-right: 0

                &:nth-child(odd)
                    &:last-child
                        width: 100%
                        margin-right: 0

    .form-wrapper
        @media screen and (max-width: 576px)
            bottom: 42px
            width: 100%
            border-radius: 0

.chat-form
    position: relative

    .ant-form-item
        width: 100%
        padding-right: 149px
        margin-bottom: 0

        .ant-form-item-control
            line-height: initial

    .chat-edit-send
        position: absolute
        top: 0
        right: 0

.chat-form.isMobile
    .ant-row.ant-form-item
        padding-right: 79px

    .custom-file-upload
        display: inline-block
        position: absolute
        top: 0
        right: 37px

        input.media-input
            display: none

.chat-form.isIOS
    .ant-row.ant-form-item
        padding-right: 111px

.web-chat-auth
    width: 100vw
    height: 100vh
    box-sizing: border-box
    position: relative
    background-color: #f0f2f5

    .chat-auth-form
        max-width: 400px
        padding: 15px
        border: 1px solid #e8e8e8
        border-radius: 5px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: white

        button
            width: 100%

.chat-auth
    width: 100%
    height: 100%
    box-sizing: border-box
    position: relative
    background-color: #f0f2f5

    &-error
        padding: 10px 15px
        border: 1px solid #fcdbd9
        border-radius: 5px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: #fef0ef

    .ant-spin-spinning
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.web-chat
    .dialog-row-bot
        text-align: left

        .dialog-message-bot
            padding-right: 0px
            padding-left: 50px

            .dialog-card-bot
                .dialog-vacancy-button, .dialog-list-button
                    cursor: pointer
                    color: #fff
                    background-color: $primary-color
                    &:hover
                        background-color: $primary-color-hover

                &:before
                    border-right: 5px solid #e8e8e8
                    border-left: none
                    left: -11px

                &:after
                    left: -4px
                    transform: rotate(135deg)

                .bot-avatar
                    left: -50px

    .dialog-row-user
        text-align: right

        .dialog-message-user
            max-width: 100%
            padding-right: 50px
            padding-left: 0
            @media screen and (max-width: 576px)
                padding: 0

            .dialog-card-user
                .dialog-card-user-placeholder
                    display: block

                &:before
                    border-left: 5px solid #fff
                    border-right: none
                    right: -11px
                    left: initial

                &:after
                    transform: rotate(-45deg)
                    right: -5px
                    left: initial

                &.error
                    box-shadow: 0px 0px 0px 1px red

                    &:after
                        box-shadow: 1px 1px 0px 0px red

                @media screen and (max-width: 576px)
                    &:before,&:after
                        display: none

                .user-avatar
                    right: -50px
                    left: initial
                    @media screen and (max-width: 576px)
                        display: none

                    .message-error
                        .message-error-button
                            width: 40px
                            height: 40px
                            top: 0
                            right: 0px
                            position: absolute
                            background: rgba(0, 0, 0, 0.35)
                            border: 0
                            color: white
                            border-color: red

    .dialog-row-bot
        .dialog-list
            .dialog-list-item
                color: #fff
                background-color: $primary-color
                margin: 10px 0 0
                &:hover
                    cursor: pointer
                    background-color: $primary-color-hover

.start-chat-menu-wrapper
    height: 100%
    width: 100%
    background: url("../images/bg-bot.jpg") center no-repeat
    @media screen and (max-width: 576px)
        background: url("../images/bg-bot-mobile.png") center repeat
        background-size: 70%

    .start-chat-menu
        box-sizing: border-box
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

        .start-chat-menu-with-title
            background: rgba(0, 0, 0, 0.35)
            padding: 15px
            border-radius: 5px
            min-width: 300px

        .start-chat-menu-title
            color: #ffffff
            margin-bottom: 10px

            .start-bot-title .deep-link_icon
                margin-top: -2px
                height: 30px

        .start-chat-menu-buttons
            display: flex
            justify-content: space-around

        .start-chat-menu-button
            margin-right: 10px
            flex: 1

            &:last-child
                margin-right: 0

.rcs-custom-scroll .rcs-inner-handle
    background-color: $primary-color

.custom-media-input
    position: absolute
    top: 0
    right: 37px
    display: inline-block
    vertical-align: top

    input.media-input
        display: none

    .media-custom-button
        line-height: 1.5
        vertical-align: top
        display: inline-block
        font-weight: 400
        text-align: center
        touch-action: manipulation
        cursor: pointer
        background-image: none
        border: 1px solid transparent
        white-space: nowrap
        padding: 0 15px
        font-size: 14px
        border-radius: 4px
        height: 32px
        user-select: none
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
        position: relative
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015)
        color: rgba(0, 0, 0, 0.65)
        background-color: #fff
        border-color: #d9d9d9
        padding-left: 8px
        padding-right: 8px
        color: #fff
        background-color: #002140
        border-color: #002140

        &:hover
            color: #fff
            background-color: #0c304d
            border-color: #0c304d

        span
            line-height: 32px

    .media-custom-button-disabled
        color: rgba(0, 0, 0, 0.25)
        background-color: #f5f5f5
        border-color: #d9d9d9
        text-shadow: none
        box-shadow: none

        &:hover
            color: rgba(0, 0, 0, 0.25)
            background-color: #f5f5f5
            border-color: #d9d9d9
            text-shadow: none
            box-shadow: none
            cursor: not-allowed

.webchat-popover
    .ant-popover-content
        &:after
            content: ''
            position: absolute
            bottom: 5px
            border-right: 5px solid rgba(0, 0, 0, 0.35)
            border-bottom: 5px solid rgba(0, 0, 0, 0.35)
            border-left: 5px solid transparent
            border-top: 5px solid transparent
            background: transparent
            -webkit-transform: translateX(-50%) rotate(45deg)
            transform: translateX(-50%) rotate(45deg)
            left: 50%

        .ant-popover-arrow
            display: none

        .ant-popover-inner
            background: rgba(0, 0, 0, 0.35)

.file-item
    color: #ffffff
    width: 200px
    position: relative

    .file-icon
        font-size: 21px
        position: absolute

    .file-name
        line-height: 21px
        width: 100%
        padding-left: 21px
        padding-right: 47px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    button.ant-btn[type="button"]
        position: absolute
        width: 21px
        height: 21px

        &.file-item-close-button
            padding: 0
            top: 0
            right: 26px
            margin-right: 0

        &.file-item-post-button
            padding: 0
            top: 0
            right: 0
