.loading-dots
    display: inline-block
    text-align: center

    .dot
        display: inline-block
        margin-left: 5px
        margin-right: 5px
        opacity: 0
        height: 10px
        width: 10px
        border-radius: 50%
        background-color: #ffffff
        animation: showHideDot 1.5s ease-in-out infinite

        &.one
            animation-delay: 2.2s
        &.two
            animation-delay: 2.4s
        &.three
            animation-delay: 2.6s

@keyframes showHideDot
  0%
        opacity: 0
  50%
        opacity: 1
  60%
        opacity: 1
  100%
        opacity: 0
