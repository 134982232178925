.question-translate-header
    border-top: 1px solid #e8e8e8
    border-bottom: 1px solid #e8e8e8
    background: #fafafa
    font-weight: 500
    font-size: 14px
    margin: 0 -24px
    padding: 15px 24px

    .question-translate-col
        color: #002140

.question-translations
    position: relative
    .labels-col
        .labels-wrapper
            padding-top: 29px
            .label
                line-height: 32px
                margin-bottom: 5px
                color: #002140
                white-space: nowrap
    .question-translations-content
        &>.ant-col
            border-right: 1px solid #e8e8e8
            height: 100%
            &:nth-child(4), &:nth-child(1)
                border-right: none
        .language
            font-size: 19px
            color: #002140
        .choises
            padding-top: 45px
    .choises-questions-row
        background-color: #fafafa
        border-top: 1px solid #e8e8e8
        .labels-col
            .labels-wrapper
                padding-top: 0
        &>.ant-col
            border-right: 1px solid #e8e8e8
            height: 100%
            padding-top: 10px
            &:nth-child(4), &:nth-child(1)
                border-right: none

.question-translate-item
    padding: 8px 0
    border-bottom: 1px solid #e8e8e8
    .question-translate-col
        display: flex
        align-items: center
        .question-translate-label
            padding-right: 10px
            flex-shrink: 0
        .question-translate-value
            flex-grow: 1
            height: 32px
            padding: 4px 11px
            color: rgba(0, 0, 0, 0.65)
            font-size: 14px
            line-height: 1.5
            background-color: #fff
            background-image: none
            border: 1px solid #d9d9d9
            border-radius: 4px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

.composite-question
    border-bottom: 1px solid #e8e8e8
    .main-question
        .question-translate-item
            border: none
    .sub-questions
        .question-translate-item
            border: none
            .question-translate-col:first-child
                padding-left: 50px!important
                &:before
                    content: ''
                    position: absolute
                    width: 10px
                    height: 2px
                    background-color: #061429
                    left: 25px
                    top: 50%
                    transform: translateY(-50%)
        .choices-question
            .ant-collapse
                border: none
                .ant-collapse-content
                    width: calc(100% - 45px)
                    margin-left: 45px

.choices-question
    .ant-collapse,
    .ant-collapse-item,
    .ant-collapse-content
        border: none
        background-color: transparent
    .ant-collapse
        border-bottom: 1px solid #e8e8e8
        background-color: transparent
        border-radius: 0
    .ant-collapse-item
        background-color: transparent
        .ant-collapse-header
            background-color: transparent
            padding: 0
            .question-translate-item
                border: none
            i
                z-index: 100
                height: 32px
                border: 1px solid black
                width: 30px
                right: 0!important
                margin-right: 0px
                border-radius: 0 4px 4px 0
                background-color: #002140
                svg
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    color: #ffffff
            .question-translate-item
                .question-translate-col:last-child
                    padding-right: 0!important
                    .question-translate-value
                        margin-right: 35px
                        border-top-right-radius: 0
                        border-bottom-right-radius: 0
        .ant-collapse-content
            border-right: 1px solid #e8e8e8
            border-left: 1px solid #e8e8e8
            border-bottom: 1px solid #e8e8e8
            position: relative
            top: -10px
            .ant-collapse-content-box
                margin-top: 2px
                background: #fafafa
                .sub-questions
                    .sub-questions-title
                        color: #002140
                        padding-bottom: 8px
                    .question-translate-value
                        height: 32px
                        padding: 4px 11px
                        color: rgba(0, 0, 0, 0.65)
                        font-size: 14px
                        line-height: 1.5
                        background-color: #fff
                        background-image: none
                        border: 1px solid #d9d9d9
                        border-radius: 4px
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                        margin-bottom: 16px
                        &:last-child
                            margin-bottom: 0px
        &.ant-collapse-item-active
            .ant-collapse-header
                i
                    svg
                        transform: translate(-50%, -50%) rotate(90deg)!important

.language-select
    position: absolute
    z-index: 100
    top: 0
    right: 24px
    line-height: 55px

.transalation-status
    white-space: nowrap
    .transalation-status-item
        display: inline-block
        padding-right: 5px
        .language
            vertical-align: middle
            display: inline-block
            padding-right: 3px
        .status-icon
            font-size: 17px
            vertical-align: middle
            color: #8c8c8c
            padding-bottom: 1px
            &.translated
                color: #52c41a

.row-language-button
    &.translated
        color: #52c41a

.classifier-translate
    .classifier-translate-item
        border-top: 1px solid #e8e8e8
        border-bottom: 1px solid #e8e8e8
        padding: 8px 0
        margin: 0!important
        &:first-child
            border-top: 0
        .classifier-translate-col
            display: flex
            align-items: center
            .translate-value
                flex-grow: 1
                height: 32px
                padding: 4px 11px
                color: rgba(0, 0, 0, 0.65)
                font-size: 14px
                line-height: 1.5
                background-color: #fafafa
                background-image: none
                border: 1px solid #d9d9d9
                border-radius: 4px
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

    .classifier-translate-item.option
        border: none
        .classifier-translate-col
            padding-left: 50px!important
            position: relative
            &::before
                content: ''
                position: absolute
                width: 10px
                height: 2px
                background-color: #061429
                left: 25px
                top: 50%
                transform: translateY(-50%)
                z-index: 10000

.vacancy-translation-modal
    .ant-modal
        width: calc( 100vw - 40px )!important

        .wysiwyg-disabled
            background-color: #f5f5f5
            color: rgba(0, 0, 0, 0.25)

        .modal-form-toolbar
            width: 100%

        .label
            color: #002140

        .description-row
            .ant-col-7
                height: auto
                .ant-form-item
                    height: 100%
                    .ant-form-item-control-input
                        height: 100%
                        .ant-form-item-control-input-content
                            height: 100%
                            .editorWrapper
                                height: 100%
                                padding-bottom: 20px
                                .wysiwyg-wrapper
                                    height: 100%
                                    margin: 0
                                    overflow: hidden

.vacancy-translation-col
    position: relative
    .vacancy-translation-button
        position: absolute
        bottom: 5px
        right: 0
