.company-users
    text-align: center

    .company-users-link
        font-size: 16px

.company-history-line
    display: flex
    .date
        margin-right: 10px
    .author
        margin-left: 10px

