.add-dictionary-item-block
    display: flex
    width: 100%
    padding: 20px
    padding-bottom: 0
    justify-content: space-between

.add-dictionary-item-block .ant-form-item
    margin-right: 15px
    flex-grow: 1

.ant-row.toolbar .company-dictionaries-buttons
    white-space: nowrap
    float: right
    .ant-btn
        margin-right: 15px
        &:last-child
            margin-right: 0
