.metro-modal
    top: 50px
    max-width: 1200px

.metro *, .metro-line *
    user-select: none

.metro-select
    .ant-btn
        height: 32px

.metro-line
    display: inline-block
    position: relative
    margin-right: 10px
    &:last-child
        margin-right: 0

    .metro-with-popover &
        float: left
        margin-right: 5px

        & + .metro-other-icon
            line-height: 24px
            display: inline-block

    .vacancy-block_description &
        line-height: 25px

    .line-badge
        position: relative
        display: inline-block
        width: 18px
        height: 18px
        line-height: 14px
        border-radius: 50%
        background-color: white
        border: 2px solid white
        color: white
        text-align: center
        font-size: 9px
        margin-right: 5px
        top: -2px

        &.line-1
            background-color: #E42313
            border-color: #E42313
        &.line-2
            background-color: #4AAF4F
            border-color: #4AAF4F
        &.line-3
            background-color: #0072BA
            border-color: #0072BA
        &.line-4
            background-color: #1EBCEF
            border-color: #1EBCEF
        &.line-5
            background-color: #924F30
            border-color: #924F30
        &.line-6
            background-color: #F07E23
            border-color: #F07E23
        &.line-7
            background-color: #943E90
            border-color: #943E90
        &.line-8
            background-color: #FFCD1C
            border-color: #FFCD1C
        &.line-8A
            background-color: #FFCD1C
            border-color: #FFCD1C
        &.line-9
            background-color: #ADACAC
            border-color: #ADACAC
        &.line-10
            background-color: #BED031
            border-color: #BED031
        &.line-11
            background-color: #88CDCF
            border-color: #88CDCF
        &.line-11A
            background-color: #88CDCF
            border-color: #88CDCF
            font-size: 8px
            width: 20px
        &.line-12
            background-color: #BAC8E8
            border-color: #BAC8E8
        &.line-13
            background-color: #0072B9
            border-color: #0072B9
        &.line-14
            color: #E42325
            border-color: #E42325
        &.line-15
            background-color: #D68AB2
            border-color: #D68AB2
        &.line-D1
            color: #EE9E2C
            border-color: #EE9E2C
        &.line-D2
            color: #DE477C
            border-color: #DE477C
        &.spb.line-1
            background-color: #ED0004
            border-color: #ED0004
        &.spb.line-2
            background-color: #3A70BB
            border-color: #3A70BB
        &.spb.line-3
            background-color: #329244
            border-color: #329244
        &.spb.line-4
            background-color: #F2A820
            border-color: #F2A820
        &.spb.line-5
            background-color: #652C91
            border-color: #652C91
    &-tag
        .line-badge
            top: 0

.metro
    height: 100%
    width: 100%
    & + .ant-btn
        position: absolute
        right: 20px
        bottom: 20px

    .moscow_metro_map
        cursor: -webkit-grab
        cursor: grab
        transition: opacity 0.5s
        font-size: 0.7em

        .drag
            cursor: -webkit-grabbing
            cursor: grabbing

    .moscow_metro_map__station
        position: relative
        display: block
        cursor: pointer

        text
            color: #333333

    .moscow_metro_map__substrate
        fill: rgba(255,255,255,0.6)

    .selected .moscow_metro_map__substrate
        fill: rgba(255,205,30,0.5)

    .moscow_metro_map__area
        fill: rgba(255,255,255,0)

    .moscow_metro_map__check
        opacity: 0
        transition: 0.2s

        &.selected
            opacity: 1

.map-modal-link
    cursor: pointer
    margin-left: 5px
    svg
        fill: rgb(24, 144, 255)

.metro-modal
    height: 90%

    .ant-modal-content
        height: 100%

        .ant-modal-body
            height: 100%
            overflow: hidden
            padding: 0
