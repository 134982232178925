@import 'variables'

.message-nodes
    display: flex
    align-items: flex-start
    width: fit-content
    margin-top: 20px
    .last-node
        margin-right: 30px
    .add-message-btn
        margin: 0 30px
    &-transitions
        margin-left: 30px
    &-container
        width: calc(100% + 60px)
        margin-left: -30px
        min-height: calc(100vh - 168px)
        overflow-y: auto
        background-color: #f0f2f5
        display: flex
        align-items: flex-start
    .message-nodes-block
        .message-text
            width: calc(100% - 35px)
            &-container
                position: relative
                .public-DraftStyleDefault-block
                    margin: 0
                button
                    position: absolute
                    top: -3px
                    right: 0
                    &.invalid
                        border-color: red
                        color: red

.message-btns
    margin-top: 20px
    text-align: center
    &-add
        margin-top: 10px
    .message-button
        width: 100%
        position: relative
        .DraftEditor-editorContainer, .DraftEditor-root
            height: initial
            line-height: 1.5715
        .anticon-right
            position: absolute
            top: 9px
            right: 3px
            font-size: 13px
        .public-DraftStyleDefault-block
            margin: 0
        &-drag-handle
            color: #fff
            position: absolute
            top: 0
            left: 0
            z-index: 1
            display: flex
            height: 100%
            align-items: center
            padding: 0 5px
            cursor: move
        &-container
            position: relative
            margin-top: 10px
            &.edit
                .message-button
                    cursor: auto
                    &.active
                        &:hover
                            background-color: #096dd9
                            border-color: #096dd9
            &:hover
                .message-button-toolbar
                    display: block
            .withoutHandler
                background-color: #d9d9d9
                border-color: #d9d9d9
                color: #000000
        &-toolbar
            position: absolute
            top: 0
            right: -38px
            display: none
            padding-left: 3px
            z-index: 2
            button
                margin-right: 3px

.menu-settings
    &-header
        display: flex
        justify-content: space-between
        button.invalid
            border-color: red
            color: red
    .message-btns
        margin-top: 10px

.message-nodes-block
    background: #fff
    padding: 15px
    border-radius: 10px
    width: 300px
    margin-left: 30px
    position: relative
    &:hover
        .message-nodes-block-remove
            display: block
    &-remove
        position: absolute
        top: -15px
        right: -15px
        display: none

.message-nodes-preview
    margin-left: 50px
