.collapse-custom
  padding: 0
  margin: 0 -15px 24px
  background: #fafafa
  font-weight: 500
  border: 0
  border-radius: 0
  border-top: 1px solid #f0f0f0
  & > .ant-collapse-item
    border-bottom: 1px solid #f0f0f0
    & > .ant-collapse-header
      padding: 16px 16px 16px 40px
      .ant-collapse-arrow
        top: 8px
