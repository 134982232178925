.classifier-table
    .ant-table-expanded-row
        td
            padding-left: 0
        td:last-child
            padding-top: 0
            padding-bottom: 0
            padding-right: 0
        .options-add-button-row
            padding-left: 60px
    .ant-table-thead
        .ant-table-cell
            &:nth-child(2)
                width: 40%

.classifier-section-buttons
    .ant-btn
        margin-right: 5px
    .ant-btn:last-child
        margin-right: 0

.options-table
    display: table
    width: 100%
    border-collapse: collapse

    .options-row
        display: table-row
        border-bottom: 1px solid #e8e8e8

        &:hover
            & > .options-name, & > .options-buttons, .options-translation
                background: #e6f7ff
        .options-name
            padding: 16px
            display: table-cell
            width: 40%
        .options-translation
            padding: 16px
            display: table-cell
        .options-empty
            display: table-cell
            width: 49px
        .options-buttons
            padding: 16px
            display: table-cell
            width: 138px
            .ant-btn
                margin-right: 5px
            .ant-btn:last-child
                margin-right: 0

.options-add-button-row
    padding: 10px

.classifier-options-spinner
    width: 100%
    padding: 30px 50px 30px 0

.vacancy-form-classifier
    .vacancy-form-classifier-options
        padding-left: 15px

.vacancy-classifiers
    padding-left: 10px

.classifiers-view
    margin-left: -4px

    .ant-tree-list-holder-inner
        .ant-tree-treenode
            .ant-tree-switcher
                display: none

    .ant-tree-treenode-disabled
        .ant-tree-node-content-wrapper
            color: #000
            cursor: default

