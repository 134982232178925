.domain-table
    .domain-buttons
        margin-bottom: 10px

        .ant-btn
            vertical-align: middle

        .ant-btn + .ant-btn
            margin-left: 10px

.ant-modal.domain-modal
    .ant-modal-body
        .ant-form
            .ant-btn[type="submit"]
                margin-top: 15px

.domains-checkboxes-wrapper
    display: inline-flex
    .domain-spin
        text-align: center
    .ant-form-item-label
        padding: 0
    .domains-checkboxes
        display: flex
        flex-direction: column
