
.draggable-list
    width: 100%
    &.empty, .empty-placeholder
        position: relative
        border-bottom: 1px dashed #e8e8e8
        min-height: 45px
        &:not(.selected)
            border-top: 1px dashed #e8e8e8
        &.disabled
            background: rgb(245, 245, 245)
        &:before
            content: '+'
            font-size: 30px
            color: rgba(0, 0, 0, 0.25)
            position: absolute
            top: calc(50% - 25px)
            left: calc(50% - 10px)
        &.clickable
            cursor: pointer
            &:hover
                &:before
                    color: rgba(0, 0, 0, 0.45)

.draggable-item
    user-select: none
    background: #fff
    border-bottom: 1px solid #e8e8e8
    .ant-form-item
        margin-bottom: 0
        .error-message
            display: none
    &.disabled
        background: rgb(245, 245, 245)
        color: rgba(0, 0, 0, 0.25)
        &:first-child
            border-top: 1px solid #e8e8e8
    &-row
        width: 100%
        display: flex
        padding: 8px 0
        &:hover
            background: #e6f7ff
            &+.composite
                background: #e6f7ff
    &-cell
        display: table-cell
        vertical-align: middle
        padding: 0 15px
        .move-button
            white-space: nowrap
        .lock-view
            min-width: 14px
            height: 24px
            display: inline-flex
            align-items: flex-end


.draggable-separator
    margin: 15px 0
    height: 1px
    border-top: 2px dashed #e8e8e8

.draggable-titles
    display: flex
    width: 100%
    border-top: 1px solid #e8e8e8
    border-bottom: 1px solid #e8e8e8
    background: #fafafa

    &-chatbot
        flex: 1 0 auto

    & > div
        display: table-cell
        vertical-align: middle
        padding: 15px
        font-weight: 500
        color: rgba(0, 0, 0, 0.85)
        &:nth-child(1)
            flex: 1 0 auto
            padding-left: 30px
        &:nth-child(2)
            padding-left: 30px
        &:nth-child(3)
            text-align: center
        &:nth-child(4)
            width: 95px

.draggable-content
    &.editable
        .draggable-titles > div:last-child
            padding-right: 130px

        .draggable-list
            &.selected
                .draggable-item
                    &-cell

                        &:nth-child(1)
                            flex: 1 0 auto
                            position: relative
                            padding-left: 30px
                            .dnd-icon
                                position: absolute
                                left: 0
                                z-index: 100
                                width: 30px
                                text-align: center
                                top: 50%
                                transform: translateY(-50%) rotate(-45deg)
                                font-size: 19px
                            .dnd-info-tooltip
                                position: absolute
                                top: 5px
                                right: -5px
                        &:nth-child(3)
                            text-align: center
                        &:nth-child(4)
                            text-align: center
                        &:nth-child(5)
                            width: 146px
                            text-align: right
                            white-space: nowrap
                        &-chatbot
                            flex: 1 0 auto

.draggable-content
    &.editing-question-id
        .draggable-titles > div:nth-child(3)
            padding-right: 187px

.sub-questions
    .draggable-content
        .draggable-titles > div:nth-child(3)
            padding-right: 127px

.draggable-content.editable
    .composite
        .draggable-item-row
            position: relative
            &:hover:before
                content: ''
                display: block
                position: absolute
                width: 30px
                background-color: #ffffff
                left: 0
                top: 0
                bottom: 0
            .draggable-item
                &-cell:nth-child(1)
                    padding-left: 65px
                    .dnd-icon
                        left: 35px
        &.disabled
            .draggable-item-row
                .draggable-item-cell:first-child:before
                    background-color: rgba(0, 0, 0, 0.25)

        .composite-buttons-row
            width: 100%
            padding: 5px 15px 0

            .composite-add-button
                margin-left: 35px

        .questions-error
            color: #f5222d
            padding-left: 15px
            padding-top: 5px
            padding-bottom: 5px

.draggable-content.editable.form-is-disabled
    .draggable-titles > div:nth-child(3)
        padding-right: 90px
    .draggable-list
        .draggable-item-row
            &:hover
                background: initial
                &+.composite
                    background: initial
            .draggable-item-cell
                .dnd-icon
                    color: #98a5b2

.draggable-item
    .composite
        overflow: hidden
.draggable-item.draggable
    .border-wrapper
        background-color: #ffffff
        border: 1px dashed #e8e8e8 !important
    .composite
        max-height: 0

.draggable-content.editable.drag-is-disabled
    .draggable-titles > div:nth-child(1)
        padding-left: 15px
    .draggable-list
        .draggable-item-row
            .draggable-item-cell:nth-child(1)
                padding-left: 15px
                .dnd-icon
                    display: none
        .composite
            .draggable-item-row
                .draggable-item-cell:nth-child(1)
                    padding-left: 35px
                    .dnd-icon
                        display: none
