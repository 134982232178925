img.web-chat-media-image
    max-width: 100px
    max-height: 100px
    margin: 5px
    cursor: pointer

.web-chat-media-video
    max-width: 100%
    background-color: rgba(158, 158, 158, .85)

.web-chat-media-file
    max-width: 440px
    background-color: #002140
    border-color: #002140
    color: #fff
    border: 1px solid transparent
    text-align: center
    line-height: 32px
    padding: 0 15px
    border-radius: 4px
    margin-bottom: 5px
    margin-top: 4px

    &:hover
        background-color: #0c304d
        border-color: #0c304d

    .web-chat-media-file-icon
        font-size: 20px
        margin-right: 5px

    .web-chat-media-file-link
        color: #fff

.file-wrapper
    display: inline-block

.file-link-wrapper
    white-space: nowrap
